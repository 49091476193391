body {
    font-family: "Be Vietnam", sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #261b0d;
    background-color: #fff8f0;
}

.quill .ql-container {
    height: 120px;
}

.card-shadow {
    box-shadow: 0 0.2px 0.4px -2px rgba(0, 0, 0, 0.018),
        0 0.4px 1.1px -2px rgba(0, 0, 0, 0.022),
        0 0.8px 2px -2px rgba(0, 0, 0, 0.025),
        0 1.3px 3.6px -2px rgba(0, 0, 0, 0.028),
        0 2.5px 6.7px -2px rgba(0, 0, 0, 0.035),
        0 6px 16px -2px rgba(0, 0, 0, 0.07);
}

.shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.shadow-inner-lg {
    box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.06);
}